:root {
	--widgetNewsTransition-duration: 0s;
}

@font-face {
	font-family: quentinFont;
	src: url('../../assets/fonts/QuentinPro.woff');
}

div[id^='widgetImage'] {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
	display: none;
	overflow: hidden;
	background: rgba(255, 255, 255, 1);
	animation: fadeIn 1s forwards;
}

div[id^='widgetImage'] img {
	animation: zoomIn 16s forwards;
	opacity: 0.9;
}

div[id^='shadow'] {
	position: absolute;
	bottom: 0;
	width: 100%;
	font-family: quentinFont;
	z-index: 2;
	color: white;
	display: none;
	border-left: 20px solid red;
	align-items: center;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 30%,
		rgba(0, 0, 0, 0.3841911764705882) 80%
	);
}

div[id^='shadow'] h1 {
	font-size: 90px;
	margin-left: 20px;
	position: absolute;
	animation: slideLeftNews 1s forwards;
	letter-spacing: 7px;
	text-shadow: 3px 3px black;
	padding: 10px;
	line-height: 1;
}

div[id^='widgetContent'] {
	display: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

#widgetContentP {
	height: 100%;
	padding: 10px;
	font-size: 75px;
	position: relative;
	animation: slideLeftNews 1s forwards;
	overflow: hidden;
	text-shadow: 3px 3px black;
	line-height: 1;
	letter-spacing: 3px;
	margin-left: 30px;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slideLeftNews {
	0% {
		left: -20px;
		opacity: 0;
	}

	100% {
		left: 0;
		opacity: 1;
	}
}

@keyframes zoomIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		transform: scale(1.1);
	}
}
